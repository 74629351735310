import React from "react";
import _ from "lodash";
import { useHistory } from "react-router-dom";

export const Home = () => {
  const history = useHistory();

  const vaiCardapio = () => {
    history.push({
      pathname: "/cardapio",
    });
  };

  const normal = () => {
    return (
      <div className="profile-info">
        <br />
        <b>
          Horários <br />
          <br />
          <span>Drive-thru (com horário agendado):</span>
        </b>
        <br />
        <br />
        <br />
        Almoço: quarta a sábado - 11:30h às 15h
        <br />
        Jantar: quarta a sábado - 19h às 22h
        <br />
        Domingo: 11h às 15h
        <br />
        Atendimento presencial somente com reserva
      </div>
    );
  };

  const ferias = () => {
    return (
      <div className="profile-info">
        <br />
        <b>Fechado para férias.</b>
        <br />
        Retornaremos renovados dia 08/08, às 11:30.
      </div>
    );
  };

  return (
    <div className="topMost" style={{ backgroundColor: "#7a0f1e" }}>
      <div className="containerFix">
        <div className="linktree">
          {normal()}

          <a href="https://wa.me/+5511963090880" className="links">
            Reserva
          </a>
          <a
            href="instagram://user?username=tonhoirestaurante"
            className="links"
          >
            Instagram
          </a>
          <a href="https://wa.me/+5511963090880" className="links">
            Whatsapp
          </a>
          <a onClick={vaiCardapio} className="links">
            Cardápio
          </a>
          <a href="tel:+551137213268" className="links">
            1137213268
          </a>
          <a href="mailto://rh@tonhoi.com.br" className="links-trabalhe">
            Trabalhe conosco
          </a>

          <div className="profile-name">
            Av. Prof. Francisco Morato, 1484 <br />
            Butantã São Paulo - Brasil, 05512-100
          </div>
        </div>
        <div style={{ height: "160px", backgroundColor: "#7a0f1e" }} />
      </div>
    </div>
  );
};
